import { useApp } from "./useApp"

export const useGeocode = () => {
  const {
    config: {
      services: { googleMaps },
    },
  } = useApp()

  const queryGeocode = async query => {
    const queryString = isNaN(query) ? query : `Postcode+${query}`
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${queryString}+australia&key=${googleMaps?.apiKey}`)
    const data = await response.json()

    if (data?.status?.toLowerCase() === "ok") {
      return data.results?.[0]?.geometry?.location || {}
    }

    return {}
  }

  //:::  Definitions:                                                           :::
  //:::    South latitudes are negative, east longitudes are positive           :::
  //:::                                                                         :::
  //:::  Passed to function:                                                    :::
  //:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
  //:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
  //:::    unit = the unit you desire for results                               :::
  //:::           where: 'M' is statute miles (default)                         :::
  //:::                  'K' is kilometers                                      :::
  //:::                  'N' is nautical miles                                  :::

  const getDistance = (lat1, lon1, lat2, lon2, unit) => {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0
    } else {
      const radlat1 = (Math.PI * lat1) / 180
      const radlat2 = (Math.PI * lat2) / 180
      const theta = lon1 - lon2
      const radtheta = (Math.PI * theta) / 180
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      if (dist > 1) {
        dist = 1
      }
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      if (unit == "K") {
        dist = dist * 1.609344
      }
      if (unit == "N") {
        dist = dist * 0.8684
      }
      return dist
    }
  }

  return { queryGeocode, getDistance }
}
