import React from "react"

import { withStores } from "./withStores"
import Map from "../Map/Map"
import { StoreCardList } from "./List/StoreCardList"
import { StoreForm } from "./Form/StoreForm"
import {
  Wrapper,
  Title,
  Content,
  DetailsWrapper,
  DetailsWrapperInner,
  MapWrapper,
  SizeButtonWrapper,
  SizeButton,
  SpecialInformationWrapper,
  SpecialInformation,
} from "./StoresStyles"
import { Sections } from "../Sections/Sections"

export const Stores = withStores(
  ({
    title,
    stores,
    handleSearch,
    handleUpdateCurrentLocation,
    handleClick,
    selectedStore,
    mapStores,
    selectedState,
    setSelectedState,
    availableStateOptions,
    special,
    specialContent,
  }): JSX.Element => (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <StoreForm
          handleSearch={handleSearch}
          handleUpdateCurrentLocation={handleUpdateCurrentLocation}
          layout={"search"}
          setSelectedState={setSelectedState}
        />
        <div className="mt-2 flex flex-col items-center">
          {availableStateOptions?.length > 0 && (
            <SizeButtonWrapper>
              {availableStateOptions?.map(state => (
                <SizeButton key={state} onClick={() => setSelectedState(state)} active={state == selectedState}>
                  {state}
                </SizeButton>
              ))}
            </SizeButtonWrapper>
          )}
        </div>
      </Wrapper>
      <Content>
        <DetailsWrapper>
          <DetailsWrapperInner>
            <StoreCardList stores={stores} selectedStore={selectedStore} handleClick={handleClick} />
          </DetailsWrapperInner>
        </DetailsWrapper>
        <MapWrapper>
          {mapStores.length > 0 ? (
            <Map location={selectedStore ? selectedStore.location : null} selectedStore={selectedStore} mapStores={mapStores} />
          ) : null}
        </MapWrapper>
      </Content>
      {special?.length > 0 ? (
        <SpecialInformationWrapper layout={"desktop"}>
          <SpecialInformation>{special}</SpecialInformation>
        </SpecialInformationWrapper>
      ) : null}
      {specialContent && <Sections page={{ content: specialContent }} />}
    </>
  )
)
