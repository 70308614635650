import tw from "twin.macro"
import styled from "@emotion/styled"
import { UnderlinedButton } from "../../Styled/Button"

export const Wrapper = styled.div<WrapperProps>`
  ${tw`relative w-full py-2 text-left border-t-2 border-black cursor-pointer`}
  ${({ selected }) => (selected === "true" ? tw`` : null)}
`

export const Distance = tw.div`
  absolute top-2-8 right-0 text-14 text-grey leading-1.5 tracking-10 mb-1-2
`

export const Title = tw.h3`
  text-20 leading-1.5 tracking-10 font-bold uppercase mb-1-2 w-3/4
`

export const Text = styled.p<TextProps>`
  ${tw`text-14 leading-1.78`}
  ${({ full }) => (full === "true" ? tw`w-full` : tw``)}
  ${({ hover }) => (hover === "true" ? tw`hover:text-red` : null)}
  ${({ bold }) => (bold === "true" ? tw`font-medium` : null)}
`

export const Content = tw.div``

export const ContentHours = tw.div`flex gap-1`

export const TextEmpty = tw.p`
  text-14 leading-1.78 opacity-0
`
export const Buttons = tw.div`
  mt-1-6 flex flex-row justify-start
`
export const Button = styled(UnderlinedButton)`
  ${tw`mr-4`}
`

type WrapperProps = {
  selected: "true" | "false"
}

type TextProps = {
  full?: "true" | "false"
  hover?: "true" | "false"
  bold?: "true" | "false"
}
