import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledInput } from "../../Styled/Form"
import { Icon } from "../../Icon/Icon"

export const Container = tw.div``

export const InputInner = styled.div`
  ${tw`relative flex-1 mb-1-6`}
  input {
    ${tw`border-b border-black border-t-0 border-l-0 border-r-0 pl-4 pb-1-6 pt-0 py-0 bg-beige-light text-20`}
    :focus {
      ${tw`bg-beige-light`}
    }
    ::placeholder {
      ${tw`text-grey opacity-100`}
    }
  }
  input + div > div {
    ${tw`top-1-1 pl-0`}
  }
  svg.search-icon {
    ${tw`w-2 h-2`}
    fill: #BE1724 !important
  }
  svg.cancel-icon {
    ${tw`w-1-2 h-1-2 mt-0-5`}
  }
`

export const Input = tw(StyledInput)`h-full`

export const StyledIcon = styled(Icon)`
  ${tw`absolute left-0 top-0-3 md:top-1-2`}
`

export const ActionLink = tw.div`w-full mt-3 text-center underline cursor-pointer hover:text-red`

export const InputWrapper = tw.div`flex flex-col md:flex-row md:max-w-41 w-full mx-auto`

export const Search = styled.div<LayoutProps>`
  ${tw`md:ml-2-4 w-full mb-1-6`}
  ${({ layout }) => {
    if (layout === "search") return tw`md:max-w-11-5`
    if (layout === "instant") return tw`md:max-w-18 `
  }}
`

type LayoutProps = {
  layout: "search" | "instant"
}
