import React from "react"
import { withOpenHours } from "./withOpenHours"
import { Modal, ModalOpenButton, ModalContentsWithLayout } from "../Modal/Modal"
import { UnderlinedButton } from "../Styled/Button"
import { Title, Hours } from "./OpenHoursStyle"

export const OpenHours = withOpenHours(({ openingHours, additionalOpeningHoursLabelText }) => {
  return (
    <Modal>
      <ModalOpenButton>
        <UnderlinedButton size="primary" noMargin>
          <span>{additionalOpeningHoursLabelText}</span>
        </UnderlinedButton>
      </ModalOpenButton>
      <ModalContentsWithLayout layout={"special"}>
        <Title>{additionalOpeningHoursLabelText}</Title>
        <Hours>
          {openingHours?.map?.((openhour, idx) => {
            return (
              <div className="pb-1" key={idx}>
                {openhour.day}: {openhour.hours}
              </div>
            )
          })}
        </Hours>
      </ModalContentsWithLayout>
    </Modal>
  )
})
