import React from "react"

import { withStoreForm } from "./withStoreForm"
import { Container, InputWrapper, InputInner, Input, StyledIcon, ActionLink, Search } from "./StoreFormStyles"
import { StyledButton } from "../../Styled/Button"

export const StoreForm = withStoreForm(
  ({
    handleSubmit,
    query,
    handleChange,
    handleCurrentPosition,
    additionalSearchButtonText,
    additionalSearchPlaceholderText,
    layout,
  }): JSX.Element => (
    <Container>
      <InputWrapper>
        <InputInner>
          <StyledIcon name={"searchStore"} />
          <Input placeholder={additionalSearchPlaceholderText} value={query} onChange={handleChange} />
        </InputInner>
        <Search layout={layout}>
          <StyledButton onClick={handleSubmit} colour={"black-reverse"} size={"tertiary"} wide={"true"} buttonMargin={0.1}>
            {additionalSearchButtonText}
          </StyledButton>
        </Search>
      </InputWrapper>
      <ActionLink onClick={handleCurrentPosition}>{`Use my current location`}</ActionLink>
    </Container>
  )
)
