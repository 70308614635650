import tw, { styled } from "twin.macro"
import { RichText } from "../RichText/RichText"

export const Title = tw.h3`
  text-20 leading-1.5 tracking-10 font-bold uppercase px-2
`

export const Hours = tw.div`
  p-2 flex flex-col
`
