import React, { useState } from "react"

export const withStoreForm = Component => ({ name = "StoreForm", handleSearch, handleUpdateCurrentLocation, layout, setSelectedState }) => {
  const [query, setQuery] = useState("")

  const handleChange = event => {
    setQuery(event.target.value)
  }

  const handleSubmit = () => {
    handleSearch(query)
  }

  const handleCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(position => {
      handleUpdateCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
      setSelectedState("ALL")
    })
  }

  Component.displayName = name
  return (
    <Component
      query={query}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleCurrentPosition={handleCurrentPosition}
      additionalSearchButtonText={`Search`}
      additionalSearchPlaceholderText={`Enter postcode/suburb`}
      layout={layout}
    />
  )
}
