import React from "react"

import { withStoreCardList } from "./withStoreCardList"
import { Card } from "../Card/Card"
import { Wrapper } from "./StoreCardListStyles"

export const StoreCardList = withStoreCardList(
  ({ viewRef, stores, selectedStore, handleClick }): JSX.Element => (
    <Wrapper ref={viewRef}>
      {stores &&
        stores?.map((store, index) => (
          <Card key={index} {...store} selected={selectedStore && selectedStore.id === store.id} handleClick={handleClick} />
        ))}
    </Wrapper>
  )
)
