import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const withOpenHours = Component => ({ name = "SpecialOpenHours", openingHours }) => {
  const { template } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_STORE_OPEN_HOURS {
      template: sanityTemplateStore {
        additionalOpeningHoursLabelText
      }
    }
  `)

  // const template = {}

  const { additionalOpeningHoursLabelText } = template || {}

  Component.displayName = name
  return <Component openingHours={openingHours} additionalOpeningHoursLabelText={additionalOpeningHoursLabelText} />
}
