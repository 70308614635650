import React from "react"

import { Wrapper, Title, Text, TextEmpty, Distance, Content, ContentHours, Buttons, Button } from "./CardStyles"
import { RichText } from "../../RichText/RichText"
import { Link } from "gatsby"
import { withCard } from "./withCard"
import { SpecialOpenHours } from "../../SpecialOpenHours/SpecialOpenHours"
import { OpenHours } from "../../OpenHours/OpenHours"

export const Card = withCard(
  ({
    id,
    title,
    distance,
    specialDescription,
    addressRich,
    email,
    phone,
    openhours,
    selected,
    handleClick,
    storeUrl,
    additionalStoreDetailLinkText,
    specialTradingHours,
    showSpecialTradingHours,
  }): JSX.Element => (
    <Wrapper selected={selected ? "true" : "false"} onClick={() => handleClick(id)}>
      <Distance>{distance && distance >= 0 ? `${distance.toFixed(2)}km` : null}</Distance>
      <Title>{title}</Title>
      <TextEmpty>{`blank`}</TextEmpty>
      {specialDescription?.length > 0 && (
        <>
          <RichText>{specialDescription}</RichText>
          <TextEmpty>{`blank`}</TextEmpty>
        </>
      )}
      {addressRich && <RichText>{addressRich}</RichText>}
      {phone && (
        <Text hover={"true"}>
          <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
            {phone}
          </a>
        </Text>
      )}
      {email && (
        <Text full={"true"} hover={"true"}>
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            {email}
          </a>
        </Text>
      )}
      <Buttons>
        <Button size={"primary"} as={Link} to={storeUrl} title={additionalStoreDetailLinkText} noMargin>
          <span>{additionalStoreDetailLinkText}</span>
        </Button>
        {showSpecialTradingHours && <SpecialOpenHours specialOpeningHours={specialTradingHours} />}
        {openhours?.length ? <OpenHours openingHours={openhours} /> : null}
      </Buttons>
    </Wrapper>
  )
)
