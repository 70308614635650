import React from "react"
import { graphql } from "gatsby"

import { Stores as Page } from "../components/Stores/Stores"

export const query = graphql`
  query {
    page: sanityPageLocations {
      title
      special: _rawSpecial(resolveReferences: { maxDepth: 6 })
      specialContent: _rawSpecialContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
    stores: allSanityLocation(sort: { fields: index, order: ASC }) {
      edges {
        node {
          ...GatsbyStoreFragment
        }
      }
    }
  }
`

const Component = ({ data, ...props }) => {
  return data.page ? <Page {...props} {...data} /> : null
}
export default Component
