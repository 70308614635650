import React, { useState, useEffect } from "react"
import { useApp } from "../../hooks/useApp"

import { useGeocode } from "../../hooks/useGeocode"

export const withStores = Component => ({ name = "Stores", page, stores, ...props }) => {
  const { queryGeocode, getDistance } = useGeocode()

  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { title, special, specialContent } = page

  const [mapStores, setMapStores] = useState([])
  const [currentLocation, setCurrentLocation] = useState({ lat: null, lng: null })
  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedState, setSelectedState] = useState("ALL")
  const availableStateOptions = [...new Set(stores.edges.map(({ node }) => node?.state)), "ALL"]

  useEffect(() => {
    if (stores) {
      const problemStores = stores.edges.filter(({ node }) => !node?.location?.lat || !node?.location?.lng || !node?.slug?.current)
      console.error("Found problem stores", problemStores)

      const filteredStores = stores.edges.filter(
        ({ node }) => node?.location?.lat && node?.location?.lng && node?.slug?.current && !node?._id?.includes?.("drafts")
      )

      const mapStores =
        filteredStores
          ?.map(({ node }, index) => ({
            ...node,
            id: String(index + 1),
            url: `${routes?.STORES}/${node?.slug?.current}`,
            distance:
              currentLocation?.lat && currentLocation?.lng && node?.location?.lat && node?.location?.lng
                ? getDistance(currentLocation?.lat, currentLocation?.lng, node?.location?.lat, node?.location?.lng, "K")
                : -1,
          }))
          .filter(store => (selectedState === "ALL" ? true : store?.state === selectedState))
          .sort((a, b) => a?.distance - b?.distance) || []
      setMapStores(mapStores)
    }
  }, [stores, currentLocation, selectedState])

  const handleSearch = async query => {
    const location = await queryGeocode(query)
    if (location?.lat && location?.lng) {
      setSelectedStore(null)
      setCurrentLocation(location)
      setSelectedState("ALL")
    }
  }

  const handleClick = id => {
    const store = mapStores?.find(store => store.id === id)
    if (store) {
      setSelectedStore(store)
    }
  }

  Component.displayName = name

  const storeWithHours = mapStores?.find(store => store?.openhours?.length)

  return (
    <Component
      title={title}
      handleSearch={handleSearch}
      handleUpdateCurrentLocation={setCurrentLocation}
      handleClick={handleClick}
      stores={mapStores}
      currentLocation={currentLocation?.lat && currentLocation?.lng ? currentLocation : null}
      selectedStore={selectedStore}
      mapStores={mapStores}
      selectedState={selectedState}
      setSelectedState={setSelectedState}
      availableStateOptions={availableStateOptions}
      special={special}
      specialContent={specialContent}
    />
  )
}
