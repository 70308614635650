import React, { useEffect, useRef } from "react"

export const withStoreCardList = Component => ({ name = "StoreCardList", stores, selectedStore, handleClick }) => {
  const viewRef = useRef(null)

  useEffect(() => {
    // viewRef.current.scrollIntoView()
  }, [stores])

  Component.displayName = name
  return <Component viewRef={viewRef} stores={stores} selectedStore={selectedStore} handleClick={handleClick} />
}
