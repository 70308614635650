import tw, { styled } from "twin.macro"

import { StyledContainer } from "../Styled/Container"
import { RichText } from "../RichText/RichText"

export const Wrapper = tw.div`
max-w-67-8 mx-auto px-2-4 pt-4 pb-3 md:pt-6-4 md:pb-6
`

export const Title = tw.h1`
  text-35 leading-1.28 tracking-3 md:text-60 md:leading-none tracking-2 font-bold uppercase mb-8 text-center
`

export const Content = tw(StyledContainer)`flex flex-wrap-reverse mb-6 items-end max-w-full`

export const DetailsWrapper = tw.div`w-full flex flex-wrap items-start justify-center`
export const DetailsWrapperInner = tw.div`w-full lg:mr-2`

export const MapWrapper = tw.div`relative w-full h-63 mb-3-6`

export const SizeButtonWrapper = styled.div`
  ${tw`flex gap-1 flex-wrap justify-center`}
`

export const SizeButton = styled.button`
  ${tw`w-auto text-left uppercase border-b text-18`}
  ${({ active }) => (active ? tw`border-black` : tw`border-transparent`)}
`

export const SpecialInformationWrapper = styled(StyledContainer)`
  ${({ layout }) => {
    switch (layout) {
      case "desktop":
        return tw`block pb-8`
      case "mobile":
        return tw`block lg:hidden pb-2`
      default:
        return null
    }
  }}
`

export const SpecialInformation = styled(RichText)`
  ${tw`p-2-4 py-1-6`}
`
